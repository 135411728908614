// drill parser drill and route modes
'use strict'

module.exports = {
  DRILL: '5',
  MOVE: '0',
  LINEAR: '1',
  CW_ARC: '2',
  CCW_ARC: '3',
}
